import React from 'react';




const Footer =()=>{

return(
    <footer>
        <p>
         Copyright ©2020 Scarlett Li. All Rights Reserved.
        </p>

    </footer>
    


)



}


export default Footer;
