import * as THREE from 'three'
import React, {Suspense,useEffect,useRef, useState, useMemo, useCallback} from "react";
import {Canvas, useFrame,extend, useLoader, useThree} from 'react-three-fiber';
import { TextureLoader } from "three";

// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'

// import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass'
// import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
// import { WaterPass } from './Waterpass'
// import { ShaderPass, EffectComposer, EffectPass, RenderPass } from 'postprocessing';


import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass'
// import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
// import { WaterPass } from './test/Waterpass'

import {useSpring, animated} from 'react-spring/three';
// import { a } from 'react-spring/three'


// import { useHistory } from "react-router";
import { Router,useHistory,Redirect, withRouter,NavLink } from "react-router-dom";



extend({ EffectComposer, ShaderPass, RenderPass, AfterimagePass, UnrealBloomPass })

let MovieShader = {
    uniforms: {
        "tDiffuse": { value: null },
        "distort": { value: 0 },
        "resolution": { value: new THREE.Vector2(1.,window.innerHeight/window.innerWidth) },
        "uMouse": { value: new THREE.Vector2(-10,-10) },
        "uVelo": { value: 0 },
        "uScale": { value: 0 },
        "uType": { value: 0 },
        "time": { value: 0 }
      },
      vertexShader: `
      uniform float time;
      uniform float progress;
      uniform vec2 resolution;
      varying vec2 vUv;
      uniform sampler2D texture1;
      
      const float pi = 3.1415925;
      
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );
      }
      `,
      fragmentShader: `
      uniform float time;
      uniform float progress;
      uniform sampler2D tDiffuse;
      uniform vec2 resolution;
      varying vec2 vUv;
      uniform vec2 uMouse;
      uniform float uVelo;
     

      float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
		uv -= disc_center;
		uv*=resolution;
		float dist = sqrt(dot(uv, uv));
		return smoothstep(disc_radius+border_size, disc_radius-border_size, dist);
	}

	float map(float value, float min1, float max1, float min2, float max2) {
		return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
	}

	float remap(float value, float inMin, float inMax, float outMin, float outMax) {
		return outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
	}

	float hash12(vec2 p) {
		float h = dot(p,vec2(127.1,311.7));	
		return fract(sin(h)*83758.5453123);
	}

	vec2 hash2d(vec2 p)
	{
		vec3 p3 = fract(vec3(p.xyx) * vec3(.1031, .1030, .0973));
	    p3 += dot(p3, p3.yzx+19.19);
	    return fract((p3.xx+p3.yz)*p3.zy);
	}


      
      void main()  {
          vec2 newUV = vUv;
          
          float c = circle(newUV, uMouse, 0.0, 0.3);
          float r = texture2D(tDiffuse, newUV.xy += c * (uVelo * .5)).x;
          float g = texture2D(tDiffuse, newUV.xy += c * (uVelo * .525)).y;
          float b = texture2D(tDiffuse, newUV.xy += c * (uVelo * .55)).z;
          vec4 color = vec4(r, g, b, 1.0);

          gl_FragColor = color;
      }`
}




function Effect(props) {
    const myMouse = useRef() 
    let speed = useRef(0);
    let targetSpeed=useRef(0);
    let followMouse = useRef([0,0]);
    let time =0
    let prevMouse= useRef([0,0]);

    let uVeloRightNow=0;
    let preuVelo=0;
    // console.log(myMouse)
    useFrame(()=>{
        speed.current = Math.sqrt( (prevMouse.current[0]- props.mouse.current[0])**2 + (prevMouse.current[1]- props.mouse.current[1])**2 );
        

        targetSpeed.current -= 0.1*(targetSpeed.current - speed.current);
        followMouse.current[0] -= 0.1*(followMouse.current[0] - props.mouse.current[0]);
        followMouse.current[1] -= 0.1*(followMouse.current[1] - props.mouse.current[1]);
        prevMouse.current[0]= props.mouse.current[0];
        prevMouse.current[1]= props.mouse.current[1];
        // time +=0.05;
        myMouse.current.uniforms.uMouse.value=followMouse.current;
        // myMouse.current.uniforms.time.value = time;
        uVeloRightNow = targetSpeed.current*3;
        myMouse.current.uniforms.uVelo.value = Math.min(uVeloRightNow, 0.05);
        // console.log(uVeloRightNow);
         uVeloRightNow *=0.999;

    })
    // console.log(props.mouse.current)
    const composer = useRef()
    const { scene, gl, size, camera } = useThree()
    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1)
    return (
      <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        {/* <waterPass attachArray="passes" factor={props.hovered?1:0} /> */}
        {/* <afterimagePass attachArray="passes" uniforms-damp-value={0.3} /> */}
        {/* <unrealBloomPass attachArray="passes" args={[aspect, 1.5, 1, 0]} /> */}
  
     <shaderPass ref={myMouse} attachArray="passes" args={[MovieShader]} renderToScreen />
     
      </effectComposer>
    )
  }

//   const history = createHistory();  


  function LandingPagePic(props){
    const mesh = useRef();
    const myImage = useLoader(TextureLoader, '/assets/doublehappiness/xixi.jpg' );
  
// Set up state for the hovered and active state
const [hovered, setHover] = useState(false)
const [active, setActive] = useState(false)
    

// Rotate mesh every frame, this is outside of React without overhead
// useFrame(() => (mesh.current.opacity+= 0.001))
const {op} = useSpring({
    // op: active? [0]:[1],
    op: 1, from: {op: 0},
    config:{duration:3000}
});

const{scale} = useSpring({
    // scale: hovered ? [1.25, 1.25, 1.25] : [1, 1, 1],
    config:{duration:500, mass: 100, tension: 100, friction: 1000, precision: 1 }
})


const history = useHistory();




const { size, viewport } = useThree()


// function handleChange(event){
    if(hovered){
        props.updateCursor("onmesh")
    } else if(!hovered){
        props.updateCursor("offmesh")
    }
    
    if(active){
        props.updatePage();
    }
// }

// const imgopacity=spring.to
    return (
        <>  
      
        {/* {!active?  <Router><Redirect to='/scarfolio/DOUBLEHAPPINESS'/></Router>:null} */}
    
        <animated.mesh
        {...props}
        ref={mesh} 
        scale={scale}
        onClick={(e) => setActive(!active)}
        // onClick={handleClick}
        onPointerOver={(e) => setHover(true)}
        onPointerOut={(e) => setHover(false)}   
        
        >
        <planeBufferGeometry attach="geometry" args={[4*1.91,4,32,32]}  />
        {/* <planeBufferGeometry attach="geometry" args={[viewport.width*1.91/3, viewport.width/3,32,32]}  /> */}
        <animated.meshBasicMaterial attach="material" map={myImage} transparent='true' opacity={op} />
        

        </animated.mesh>
        <Effect mouse={props.mouse}  hovered={hovered} />
       
        </>


    )



}


const PictureOnCanvas=(props)=>{
    
    // const { size, viewport } = useThree()
    const [cursorLook, setCurser] = React.useState("offmesh");
    const handleChange=(newValue)=>{
        setCurser(newValue);
    }
    const [goDetail, setRedirect] = useState(false)

    function handleClick() {

        setRedirect(true)
        // history.push('/DOUBLEHAPPINESS/');
        // // return ( <NavLink 
                    
        // //             to={{pathname:'/scarfolio/DOUBLEHAPPINESS'}}                 
        // //             >
        // //             </NavLink>)
    }

return(
    <>
    <Canvas
    // style={{height:`${viewport.height}`,width:`${viewport.width}`}}
    // orthographic 
    // camera={{ zoom: 100, position: [0, 0, 500] }}
    className={cursorLook}
    gl={{ alpha: true, antialias: false, logarithmicDepthBuffer: true }}
   
         onCreated={({ gl }) => {
          gl.setClearColor('white')
        //   gl.toneMapping = THREE.ACESFilmicToneMapping
          gl.outputEncoding = THREE.sRGBEncoding
        }}>
             
        {/* <ambientLight />
        <pointLight position={[10,10,10]} /> */}

    <Suspense fallback={null}>
    <LandingPagePic mouse={props.mouse} updateCursor={handleChange} updatePage={handleClick}  />
    
    </Suspense>
    

    </Canvas>
      {goDetail?<Redirect to='/DOUBLEHAPPINESS'/>:null}
    </>
)

}



export default PictureOnCanvas;