import React, {useRef,useCallback,useEffect, Suspense, useContext} from 'react';
import './App.css';
// import PictureOnCanvas from './components/PictureOnCanvas'

// import PicHoverEffect from './components/PicHover1'
// import * as THREE from "three";
// import {useFrame} from 'react-three-fiber';
import PicHoverEffect from './components/PicHoverEffect';
import {Route, NavLink,Link, BrowserRouter, Switch, HashRouter} from 'react-router-dom';

import Menu from './components/Menu';
import Footer from './components/Footer';
const ErrorPage = React.lazy(()=>import('./components/ErrorPage'));
// const Content = React.lazy(()=>import('./components/ContentHorizontal/HorizontalSite'));
const DoubleHappinessContent = React.lazy(()=>import('./components/ContentVertical/DoubleHappinessContent'));
const PersimmonNight = React.lazy(()=>import('./components/ContentVertical/PersimmonNight'));
const About = React.lazy(()=>import('./components/ContentVertical/About'));

function App() {

  let mouse = useRef([0, 0]);
  
  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => {
    (mouse.current = [x/window.innerWidth,1.- y/window.innerHeight])
    
  }, [])

  return (

    <HashRouter >
    
    <div className="App" >
      <Menu />
 
      <Switch>
      
      
      <Route path='/' exact >
         
        <div className="canvasContainer" onMouseMove={onMouseMove}>
          {/* <div className="canvasContainer" > */}
          {/* <PictureOnCanvas /> */}
          <PicHoverEffect mouse={mouse}    />
        </div>
 
      
      </Route>
      {/* catch all unknown shit */}
      <Route path='/DOUBLEHAPPINESS' exact render={()=> (
      <Suspense fallback={null}>
        
        <DoubleHappinessContent />        
        
      </Suspense>)} />


      <Route path='/PERSIMMONNIGHT' exact render={()=> (
      <Suspense fallback={null}>

        <PersimmonNight />        
      </Suspense>)} />


      <Route path='/About' exact render={()=> (
      <Suspense fallback={null}>

        <About />        
      </Suspense>)} />


      <Route render={()=> (
      <Suspense fallback='null'>
        <ErrorPage />        
      </Suspense>)} />
      </Switch>
   
     <Footer />
    </div>
    
    </HashRouter>
  );
}

export default App;
