import React from 'react';
import {Route, NavLink,Link, BrowserRouter, Switch} from 'react-router-dom';
import style from './Menu.module.css';
import SplitText from "react-pose-text";
import TextFx from './TextFx';



class Menu extends React.Component {

    render(){

       return (
        <header className={style.Menu}>

          
            <nav>
            <ul>
                {/* <li><NavLink to="/" activeClassName="menu-active" exact>Home</NavLink></li> */}
                <li>
                <NavLink 
                activeClassName={style.active}
                to={{pathname:'/DOUBLEHAPPINESS'}}                 
                >
                    <SplitText initialPose="exit" pose="enter" charPoses={TextFx}>
                        double happiness
                    </SplitText>
                </NavLink>
                </li>
                <li>
                <NavLink 
                activeClassName={style.active}
                to={{pathname:'/PERSIMMONNIGHT'}}                 
                >
                    <SplitText initialPose="exit" pose="enter" charPoses={TextFx}>
                    persimmon night
                    </SplitText>
                </NavLink>
                </li>
                <li>
                <NavLink 
                activeClassName={style.active}
                to={{pathname:'/About'}} 
                >
                    <SplitText initialPose="exit" pose="enter" charPoses={TextFx}>about</SplitText>
                </NavLink>
                </li>
            </ul>
            </nav>

            <span className={style.logo}>
               <NavLink to="/" activeClassName={style['menu-active']} exact> 
                    <h1> 
                        <SplitText initialPose="exit" pose="enter" charPoses={TextFx}>
                            scarlett li
                        </SplitText>
                    </h1>
                    
            </NavLink> 
           </span>
        </header>
       )
    }
}


export default Menu;